import { type DeepReadonly, nextTick, ref } from "vue";
import { search } from "@generated/api/searchApiControllerApi";
import type { ChannelListView } from "@generated/model/channelListView";
import type { CustomPageListView } from "@generated/model/customPageListView";
import type { ActionableListView } from "@generated/model/actionableListView";
import type { ContentTypes } from "@/components/instant-search/_instant-search";
import { useI18n } from "@/i18n/i18nSetup";
import type { FormFilter, FullSearchProps, SearchFiltersForm, SummaryMetadata } from "@newgenerated/shared/schema";
import { assert, assertIsDefined } from "@utils/assertion";
import type { MultiSelectActions, MultiSelectStateProps } from "@/components/form/GaFormFieldMultiSelect";
import type { SelectOption } from "@/components/form/GaFormFieldInputSelect";
import type { ComponentSimpleInteractions, SimpleComponentUiState } from "@/components/getabstract-ai/utils/getabstractAiBackgroundWorker";

const defaultAmount: { [content in ContentTypes]: number } = {
  SUMMARY: 6,
  ACTIONABLE: 6,
  CHANNEL: 9,
  CUSTOMPAGE: 9,
};

export type DataType =
  | {
      discriminator: "SUMMARY";
      items: SummaryMetadata[];
    }
  | {
      discriminator: "CHANNEL";
      items: ChannelListView[];
    }
  | {
      discriminator: "CUSTOMPAGE";
      items: CustomPageListView[];
    }
  | {
      discriminator: "ACTIONABLE";
      items: ActionableListView[];
    };
export type ContentTypeProps = DataType & {
  amountToShow: number;
  title: string;
};

export const filterTypes = ["audioFormFilter", "languageFormFilter", "sourceFormFilter", "qualityFormFilter", "ratingFormFilter", "publicationDateFormFilter"] as const;
export type FilterType = (typeof filterTypes)[number];
export type ExtendedContentType = ContentTypes | "ALL";
export type UpdateFilter = (filterName: FilterType, newActiveValues: string[]) => void;
export type ResetFilter = (filterType: FilterType) => void;
export type FilterOption = { filterName: string; isOpen: boolean };

export type UiState = {
  filtersExtended: boolean;
  multiSelectProps: MultiSelectStateProps<string>;
  filterOptions: FilterOption[];
};

export type SearchAiComponentState = {
  uiState: SimpleComponentUiState;
  displayFullAnswer: boolean;
};

export type SummariesPaging = {
  page: number;
  totalCount: bigint;
};

export type SearchStoreState = UiState &
  ComponentSimpleInteractions & {
    contentTypeProps: ContentTypeProps[];
    formFilters: FormFilter[];
    selectedContentType: ExtendedContentType;
    loading: boolean;
    summariesPaging: SummariesPaging;
    aiState: SearchAiComponentState;
  };

export type SearchStoreActions = MultiSelectActions<string> & {
  search: () => Promise<void>;
  selectContentType: (contentType: ExtendedContentType) => void;
  updateFilter: UpdateFilter;
  resetFilter: ResetFilter;
  resetAllFilters: () => void;
  toggleFilters: () => void;
  loadMoreSummaries: () => void;
  toggleSingleFilter: (filterName: string) => void;
  showFullAiAnswer: () => void;
};

export function isFilterType(value: string): value is FilterType {
  return filterTypes.includes(value as FilterType);
}

function getFilter(formFilters: FormFilter[], filterType: FilterType): FormFilter {
  const filter = formFilters.find((filter) => filter.name === filterType);
  assertIsDefined(filter, `filter with name ${filterType} not found.`);
  return filter;
}

function convertToNumbers(input: string[]): number[] {
  return input.map((value) => {
    const number = parseInt(value);
    if (Number.isNaN(number)) {
      throw Error("could not parse number");
    }
    return number;
  });
}

function convertToSearchFiltersForm(formFilters: FormFilter[], searchTerm: string, summariesPage: number): SearchFiltersForm {
  const audioFilter = getFilter(formFilters, "audioFormFilter").activeValues.at(0);
  assert(audioFilter === "true" || audioFilter === "false" || audioFilter === undefined);
  return {
    qualityFormFilter: getFilter(formFilters, "qualityFormFilter").activeValues,
    ratingFormFilter: convertToNumbers(getFilter(formFilters, "ratingFormFilter").activeValues),
    sourceFormFilter: getFilter(formFilters, "sourceFormFilter").activeValues,
    languageFormFilter: getFilter(formFilters, "languageFormFilter").activeValues,
    audioFormFilter: audioFilter === "true",
    publicationDateFormFilter: convertToNumbers(getFilter(formFilters, "publicationDateFormFilter").activeValues),
    query: searchTerm,
    summariesPage: summariesPage,
  };
}

function applyQueryParamsToUrl(formFilters: FormFilter[]): void {
  const queryParams = new URLSearchParams(window.location.search);
  formFilters.map((filter) => {
    queryParams.delete(filter.name);
    filter.activeValues.forEach((value) => {
      if (!(filter.name === "audioFormFilter" && filter.activeValues.at(0) === "false")) {
        queryParams.append(filter.name, value);
      }
    });
  });
  history.replaceState(null, "", "?" + queryParams.toString());
}

function readQueryParametersFromUrl(formFilters: FormFilter[]): FormFilter[] {
  const queryParams = new URLSearchParams(window.location.search);
  return formFilters.map((filter) => {
    const values = queryParams.getAll(filter.name);
    return {
      ...filter,
      activeValues: values.length > 0 ? values : filter.activeValues,
    };
  });
}

export function createStore(props: FullSearchProps): {
  state: () => DeepReadonly<SearchStoreState>;
  actions: SearchStoreActions;
} {
  const { t } = useI18n();
  const state = ref<SearchStoreState>({
    contentTypeProps: [
      { discriminator: "SUMMARY", title: t("general:summaries"), amountToShow: defaultAmount["SUMMARY"], items: [] },
      { discriminator: "ACTIONABLE", title: t("general:actionables"), amountToShow: defaultAmount["ACTIONABLE"], items: [] },
      { discriminator: "CUSTOMPAGE", title: t("customPage:customPages"), amountToShow: defaultAmount["CUSTOMPAGE"], items: [] },
      { discriminator: "CHANNEL", title: t("general:channels"), amountToShow: defaultAmount["CHANNEL"], items: [] },
    ],
    searchTerm: "",
    selectedContentType: "ALL",
    loading: false,
    formFilters: readQueryParametersFromUrl(props.initialFormFilters),
    filtersExtended: false,
    multiSelectProps: {
      options: getFilter(props.initialFormFilters, "qualityFormFilter").options,
      searchTerm: "",
      selectedOptions: [],
      showSearch: false,
    },
    summariesPaging: {
      page: 0,
      totalCount: BigInt(0),
    },
    filterOptions: props.initialFormFilters.map((filter) => {
      return { filterName: filter.name, isOpen: false };
    }),
    aiState: {
      displayFullAnswer: false,
      uiState: { status: "INITIAL" },
    },
  });

  // TODO: 'AskGetabstract' feature hidden for now
  // void simpleBackgroundWorker(
  //   {
  //     streamingTokensPerSec: STREAMING_TOKEN_PER_SEC,
  //     streamingSpeedUpFactor: STREAMING_SPEED_UP_FACTOR,
  //   },
  //   {
  //     getInteractions: () => state.value,
  //     updateUi: (newState) => {
  //       state.value = { ...state.value, aiState: { ...state.value.aiState, uiState: newState } };
  //     },
  //     initQuestionAnswer: createQuestion,
  //     getQuestionAnswers: getQuestionAnswers,
  //     delay: () => delay(WAITING_INTERVAL_IN_MS),
  //     isAborted: () => false,
  //     startTimer,
  //   },
  // );

  const actions: SearchStoreActions = {
    onChange(options: SelectOption<string>[]): void {
      state.value = { ...state.value, multiSelectProps: { ...state.value.multiSelectProps, selectedOptions: options } };
      actions.updateFilter(
        "qualityFormFilter",
        options.map((o) => o.value),
      );
    },
    onSearchTermChange(value: string): void {
      state.value = { ...state.value, multiSelectProps: { ...state.value.multiSelectProps, searchTerm: value } };
    },
    toggleSearch(): void {
      state.value = {
        ...state.value,
        multiSelectProps: {
          ...state.value.multiSelectProps,
          showSearch: !state.value.multiSelectProps.showSearch,
        },
      };
    },
    search: async (): Promise<void> => {
      state.value = { ...state.value, loading: true };
      try {
        applyQueryParamsToUrl(state.value.formFilters);
        const result = await search(convertToSearchFiltersForm(state.value.formFilters, state.value.searchTerm, state.value.summariesPaging.page));
        const contentTypes = state.value.contentTypeProps.map((cat) => {
          switch (cat.discriminator) {
            case "CHANNEL":
              return { ...cat, items: result.channels };
            case "SUMMARY":
              return { ...cat, items: result.summaries };
            case "CUSTOMPAGE":
              return { ...cat, items: result.customPages };
            case "ACTIONABLE":
              return { ...cat, items: result.actionables };
          }
        });
        state.value = {
          ...state.value,
          contentTypeProps: contentTypes,
          formFilters: result.formFilters,
          summariesPaging: result.summariesPaging,
        };
      } finally {
        state.value = { ...state.value, loading: false };
      }
    },
    selectContentType: (contentType) => {
      state.value = { ...state.value, selectedContentType: contentType };
      void nextTick(() => {
        scrollTo({ top: 0 });
      });
    },
    updateFilter: (filterName: FilterType, newActiveValues: string[]) => {
      const formFilters = [...state.value.formFilters];
      const filter = getFilter(state.value.formFilters, filterName);
      filter.activeValues = newActiveValues;
      state.value = { ...state.value, formFilters: formFilters };
    },
    resetFilter: (filterType) => {
      const formFilters = [...state.value.formFilters];
      const filter = getFilter(formFilters, filterType);
      filter.activeValues = [];
      state.value = { ...state.value, formFilters };
      if (filter.name === "qualityFormFilter") {
        actions.onChange([]);
      }
    },
    toggleFilters: () => {
      state.value = { ...state.value, filtersExtended: !state.value.filtersExtended };
    },
    resetAllFilters: () => {
      state.value.formFilters.forEach((filter) => {
        assert(isFilterType(filter.name));
        actions.resetFilter(filter.name);
      });
      void actions.search();
    },
    loadMoreSummaries: () => {
      state.value = { ...state.value, summariesPaging: { ...state.value.summariesPaging, page: state.value.summariesPaging.page + 1 } };
      void actions.search();
    },
    toggleSingleFilter: (filterName: string) => {
      const filterOptions = [...state.value.filterOptions];
      const filterOption = filterOptions.find((value) => value.filterName === filterName);
      assertIsDefined(filterOption);
      filterOption.isOpen = !filterOption.isOpen;
      state.value = { ...state.value, filterOptions: filterOptions };
    },
    showFullAiAnswer: () => {
      state.value = { ...state.value, aiState: { ...state.value.aiState, displayFullAnswer: true } };
    },
  };
  if (props.query !== null && props.query !== "") {
    state.value = { ...state.value, searchTerm: props.query };
    void actions.search();
  }
  return {
    state: () => state.value,
    actions: actions,
  };
}
